<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <actor-role-show :id="id"></actor-role-show>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import ActorRoleShow from './ActorRoleShow.vue'

export default {
  components: {
    VuexBreadcrumb,
    ActorRoleShow
  },
  props: {
    id: String
  }
}
</script>